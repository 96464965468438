<template>
    <BaseContainer>
        <span slot="principal">
            <v-content>
                <v-card>
                    <v-toolbar flat>
                        <v-text-field
                            v-model="search"
                            prepend-inner-icon="mdi-magnify"
                            label="Buscar por cliente ou mesa"
                            solo
                            dense
                            hide-details
                        />
                        <v-spacer/>
                        <v-btn
                            depressed
                            color="blue-grey"
                            class="ma-2 white--text"
                            @click="finalizar"
                        >
                            Finalizar
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            v-model="selected"
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            sort-by="id"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 160"
                            :search="search"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            @click:row="efetuarPagamento"
                            show-select
                        >
                            <template v-slot:item.created_at="{ item }">
                                <span>{{ moment(item.created_at).format('DD/MM/YYYY HH:mm') }}</span>
                            </template>
                            <template v-slot:item.agendado_datahora="{ item }">
                                <span v-if="item.agendado">
                                    {{ formatAgendamento(item) }}
                                </span>
                            </template>
                            <template v-slot:item.total="{ item }">
                                <span style="color: green;">R$&nbsp;{{ formatPrice(+item.total) }}</span>
                            </template>
                            <template v-if="isAdmin" v-slot:item.action="{ item }">
                                <v-btn icon @click.stop="cancelar(item.id)" color="red">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import { mapState } from 'vuex';

export default {
    name: 'Caixa',

    components: {
        BaseContainer,
    },

    data: () => ({
        search: '',
        loading: true,
        dados: [],
        selected: [],
        headers: [
            { text: 'Cód. Pedido', value: 'id' },
            { text: 'Cliente', value: 'cliente.nome' },
            { text: 'Mesa', value: 'mesa',  align: 'center' },
            { text: 'Data Emissão', value: 'created_at',  align: 'center' },
            { text: 'Data Agendamento', value: 'agendado_datahora',  align: 'center' },
            { text: 'R$ Total', value: 'total' },
            { text: 'Cancelar', value: 'action', sortable: false, align: 'end' },
        ],
    }),

    mounted() {
        this.consultar();
    },

    computed: {
        ...mapState([
            'usuario',
        ]),

        isAdmin() {
            return this.usuario.admin;
        },
    },

    methods: {
        consultar() {
            this.loading = true;
            this.dados = [];

            this.$http.get('pedidos').then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify('Não foi possivel concluir a operação', 'warning');
                    return;
                }

                this.dados = resp.data.data;
            }).finally(() => (this.loading = false));
        },

        efetuarPagamento(row) {
            this.$store.commit('setData', row);
            this.$router.push('/checkout');
        },

        finalizar() {
            if (!this.selected.length) {
                this.notify('Selecione uma comanda', 'warning');
                return;
            }

            if (!confirm('Deseja finalizar todas as comandas selecionadas?')) {
                return;
            }

            const data = [];
            this.selected.forEach(e => {
                data.push({
                    id: e.id,
                    total: e.total,
                    forma_pagamento: e.forma_pagamento,
                });
            })

            this.$http.post('pedidos/finalizar', data).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify('Não foi possivel concluir a operação', 'warning');
                    return;
                }

                this.notify('Finalizado com sucesso');
                this.selected = [];
                this.consultar();
            });
        },

        async cancelar(id) {
            const data = { status: 'cancelado' };

            const msg = 'Deseja realmente cancelar o pedido?';
            if (!await this.$root.$confirm('Confirmação', msg, { color: 'red darken-1' })) {
                return;
            }

            this.$http.post(`pedidos/update-status/${id}`, data).then(resp => {
                if (resp.data.type == 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }

                this.notify('Pedido cancelado com sucesso');
                this.consultar();
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            });
        },

        formatAgendamento(item) {
            const parts = item.agendado_datahora.split(' ');

            const date = this.moment(parts[0]).format('DD/MM/YYYY');

            if (parts.length === 1) {
                return date;
            }

            return `${date} ${parts[1]} - ${parts[3]}`;
        },
    },
}
</script>

<style scoped>
.container  {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.container .row {
    margin: 0 auto;
}
</style>
